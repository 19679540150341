exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-generations-js": () => import("./../../../src/pages/generations.js" /* webpackChunkName: "component---src-pages-generations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-about-us-js": () => import("./../../../src/pages/info/about-us.js" /* webpackChunkName: "component---src-pages-info-about-us-js" */),
  "component---src-pages-info-contact-us-js": () => import("./../../../src/pages/info/contact-us.js" /* webpackChunkName: "component---src-pages-info-contact-us-js" */),
  "component---src-pages-info-privacy-policy-js": () => import("./../../../src/pages/info/privacy-policy.js" /* webpackChunkName: "component---src-pages-info-privacy-policy-js" */),
  "component---src-pages-info-terms-and-conditions-js": () => import("./../../../src/pages/info/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-info-terms-and-conditions-js" */),
  "component---src-pages-years-js": () => import("./../../../src/pages/years.js" /* webpackChunkName: "component---src-pages-years-js" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0001-pop-culture-evolution-from-lost-generation-to-generation-alpha-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0001-pop-culture-evolution-from-lost-generation-to-generation-alpha/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0001-pop-culture-evolution-from-lost-generation-to-generation-alpha-text-md" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0002-your-guide-to-managing-different-generations-in-the-workplace-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0002-your-guide-to-managing-different-generations-in-the-workplace/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0002-your-guide-to-managing-different-generations-in-the-workplace-text-md" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0003-video-gaming-habits-of-different-generations-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0003-video-gaming-habits-of-different-generations/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0003-video-gaming-habits-of-different-generations-text-md" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0004-things-to-consider-when-hiring-millennials-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0004-things-to-consider-when-hiring-millennials/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0004-things-to-consider-when-hiring-millennials-text-md" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0005-how-generation-x-deals-with-aging-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0005-how-generation-x-deals-with-aging/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0005-how-generation-x-deals-with-aging-text-md" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0006-gen-z-relationships-new-trends-in-love-and-intimacy-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0006-gen-z-relationships-new-trends-in-love-and-intimacy/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0006-gen-z-relationships-new-trends-in-love-and-intimacy-text-md" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0007-sandwich-generation-the-double-duty-of-gen-x-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0007-sandwich-generation-the-double-duty-of-gen-x/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0007-sandwich-generation-the-double-duty-of-gen-x-text-md" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0008-baby-boomers-on-the-move-retirement-migration-trends-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0008-baby-boomers-on-the-move-retirement-migration-trends/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0008-baby-boomers-on-the-move-retirement-migration-trends-text-md" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0009-what-professions-are-suitable-for-zoomers-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0009-what-professions-are-suitable-for-zoomers/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0009-what-professions-are-suitable-for-zoomers-text-md" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0010-fashion-trends-throughout-the-generations-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0010-fashion-trends-throughout-the-generations/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0010-fashion-trends-throughout-the-generations-text-md" */),
  "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0011-the-price-of-fame-a-critical-look-at-kidfluencers-text-md": () => import("./../../../src/templates/article.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/articles/0011-the-price-of-fame-a-critical-look-at-kidfluencers/text.md" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-articles-0011-the-price-of-fame-a-critical-look-at-kidfluencers-text-md" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-author-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-authors-kate-burrows-md": () => import("./../../../src/templates/author.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/authors/kate-burrows.md" /* webpackChunkName: "component---src-templates-author-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-authors-kate-burrows-md" */),
  "component---src-templates-author-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-authors-natasha-kaigorodova-md": () => import("./../../../src/templates/author.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/authors/natasha-kaigorodova.md" /* webpackChunkName: "component---src-templates-author-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-authors-natasha-kaigorodova-md" */),
  "component---src-templates-author-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-authors-vladimir-kaigorodov-md": () => import("./../../../src/templates/author.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/authors/vladimir-kaigorodov.md" /* webpackChunkName: "component---src-templates-author-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-authors-vladimir-kaigorodov-md" */),
  "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-alpha-md": () => import("./../../../src/templates/auto-year.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/years-auto/alpha.md" /* webpackChunkName: "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-alpha-md" */),
  "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-baby-boomers-md": () => import("./../../../src/templates/auto-year.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/years-auto/baby-boomers.md" /* webpackChunkName: "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-baby-boomers-md" */),
  "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-greatest-md": () => import("./../../../src/templates/auto-year.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/years-auto/greatest.md" /* webpackChunkName: "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-greatest-md" */),
  "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-lost-md": () => import("./../../../src/templates/auto-year.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/years-auto/lost.md" /* webpackChunkName: "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-lost-md" */),
  "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-millennials-md": () => import("./../../../src/templates/auto-year.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/years-auto/millennials.md" /* webpackChunkName: "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-millennials-md" */),
  "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-silent-md": () => import("./../../../src/templates/auto-year.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/years-auto/silent.md" /* webpackChunkName: "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-silent-md" */),
  "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-x-md": () => import("./../../../src/templates/auto-year.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/years-auto/x.md" /* webpackChunkName: "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-x-md" */),
  "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-z-md": () => import("./../../../src/templates/auto-year.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/years-auto/z.md" /* webpackChunkName: "component---src-templates-auto-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-auto-z-md" */),
  "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-1-lost-generation-1-lost-md": () => import("./../../../src/templates/generation.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/generations/1-lost-generation/1-lost.md" /* webpackChunkName: "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-1-lost-generation-1-lost-md" */),
  "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-2-greatest-generation-2-greatest-md": () => import("./../../../src/templates/generation.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/generations/2-greatest-generation/2-greatest.md" /* webpackChunkName: "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-2-greatest-generation-2-greatest-md" */),
  "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-3-silent-generation-3-silent-md": () => import("./../../../src/templates/generation.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/generations/3-silent-generation/3-silent.md" /* webpackChunkName: "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-3-silent-generation-3-silent-md" */),
  "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-4-baby-boomers-4-baby-boomers-md": () => import("./../../../src/templates/generation.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/generations/4-baby-boomers/4-baby-boomers.md" /* webpackChunkName: "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-4-baby-boomers-4-baby-boomers-md" */),
  "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-5-generation-x-5-x-md": () => import("./../../../src/templates/generation.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/generations/5-generation-x/5-x.md" /* webpackChunkName: "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-5-generation-x-5-x-md" */),
  "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-6-millennials-6-millennials-md": () => import("./../../../src/templates/generation.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/generations/6-millennials/6-millennials.md" /* webpackChunkName: "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-6-millennials-6-millennials-md" */),
  "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-7-generation-z-7-z-md": () => import("./../../../src/templates/generation.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/generations/7-generation-z/7-z.md" /* webpackChunkName: "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-7-generation-z-7-z-md" */),
  "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-8-generation-alpha-8-alpha-md": () => import("./../../../src/templates/generation.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/generations/8-generation-alpha/8-alpha.md" /* webpackChunkName: "component---src-templates-generation-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-generations-8-generation-alpha-8-alpha-md" */),
  "component---src-templates-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-1946-1946-md": () => import("./../../../src/templates/year.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/years/1946/1946.md" /* webpackChunkName: "component---src-templates-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-1946-1946-md" */),
  "component---src-templates-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-1947-1947-md": () => import("./../../../src/templates/year.js?__contentFilePath=/Users/vladimir/proj/my/whatgenerationis/content/years/1947/1947.md" /* webpackChunkName: "component---src-templates-year-js-content-file-path-users-vladimir-proj-my-whatgenerationis-content-years-1947-1947-md" */)
}

