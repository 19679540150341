import "./src/styles/global.css";

export const onRouteUpdate = ({ _, prevLocation }) => {
  if (process.env.NODE_ENV === `production`) {
    // wrap inside a timeout to ensure the title has properly been changed
    if (prevLocation) {
      setTimeout(() => {
        window.dataLayer.push({ event: `gatsby-route-change-with-prev-location` });
      }, 50);
    }
  }
};
